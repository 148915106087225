import { useMemo, useCallback, useEffect, useContext, useState } from 'react';
import { createEditor, Editor, Text, Transforms } from 'slate';
import {
  Slate,
  Editable,
  withReact,
  DefaultElement,
  ReactEditor,
} from 'slate-react';
import { withHistory } from 'slate-history';
import useSelection from 'hooks/useSelection';
import { isKeyHotkey } from 'is-hotkey';
import cn from 'classnames';

import { PostEditorContext, postEditorActions } from '../PostEditorStore';
import htmlSerializer from './htmlSerializer';
import plainSerializer from './plainSerializer';
import s from './TextEditor.module.scss';
import { ReactComponent as LinkIcon } from '../../../img/link-solid.svg';
import { ReactComponent as BoldIcon } from '../../../img/bold-solid.svg';
import { ReactComponent as ItalicIcon } from '../../../img/italic-solid.svg';
import { ReactComponent as UnlinkIcon } from '../../../img/unlink-solid.svg';
import Toolbar from './Toolbar';
import isOSX from '../../../utils/isOSX';
import { ParagraphElement, LinkElement } from './Element';
import logger from 'utils/logger';
import {
  toggleBold,
  toggleItalic,
  toggleLink,
  insertSoftBreak,
  isLinkSelected,
  isBoldActive,
  isItalicActive,
} from './EditorHelpers';
import Tooltip from 'components/Tooltip/Tooltip';

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isLinkHotkey = isKeyHotkey('mod+k');
const isSoftBreakHotkey = isKeyHotkey('shift+enter');

const withHtmlPasting = (editor) => {
  const { insertData, isInline } = editor;

  // Configure Slate to handle links as inline
  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertData = (data) => {
    const html = data.getData('text/html');

    if (html) {
      const fragment = htmlSerializer.deserializeString(html);

      try {
        Transforms.insertFragment(editor, fragment);
      } catch (error) {
        // Pasting some html snippets may cause non fatal errors
        logger.error('Error pasting:', error);
      }

      return;
    }

    insertData(data);
  };

  return editor;
};

const getRangesForLineBreaks = (text, path) => {
  let ranges = [];
  const char = '\n';
  let start = 0;
  while ((start = text.indexOf(char, start)) !== -1) {
    const end = start + char.length;
    ranges.push({
      anchor: { path, offset: start },
      focus: { path, offset: end },
      newlineMatch: true,
    });
    start = end;
  }

  return ranges;
};

const decorate = ([node, path]) => {
  if (Text.isText(node, path)) {
    const { text } = node;
    return getRangesForLineBreaks(text, path);
  }

  return [];
};

const renderElement = (props) => {
  const { element } = props;
  if (element.type === 'paragraph') {
    return <ParagraphElement {...props} />;
  }

  if (element.type === 'link') {
    return <LinkElement {...props} />;
  }

  return <DefaultElement {...props} />;
};

const renderLeaf = ({ attributes, children, leaf }) => {
  let className = '';

  if (leaf.newlineMatch) {
    className += 'isNewline';
  }

  if (leaf.bold && leaf.italic) {
    return (
      <em>
        <strong className={className} {...attributes}>
          {children}
        </strong>
      </em>
    );
  }

  if (leaf.bold) {
    return (
      <strong className={className} {...attributes}>
        {children}
      </strong>
    );
  }

  if (leaf.italic) {
    return (
      <em className={className} {...attributes}>
        {children}
      </em>
    );
  }

  return (
    <span className={className} {...attributes}>
      {children}
    </span>
  );
};

const getCharCount = (editor) => {
  return plainSerializer.unsafeSerialize(editor).length;
};

const SMALL_TEXT_LENGTH_LIMIT = 600;
const MEDIUM_TEXT_LENGTH_LIMIT = 700;

// Not same logic as for articles:
// https://git.svt.se/news/service-news-render/-/blob/master/application/app/components/Artikelkollen/_utils.js#L79
const textLengthIndicator = (textLength) => {
  if (textLength < SMALL_TEXT_LENGTH_LIMIT) {
    return 'Small';
  } else if (textLength < MEDIUM_TEXT_LENGTH_LIMIT) {
    return 'Medium';
  }
  return 'Large';
};

const TextEditor = () => {
  const editor = useMemo(
    () => withHtmlPasting(withHistory(withReact(createEditor()))),
    []
  );
  const [selection, setSelection] = useSelection(editor);
  const {
    setOnResetEditor,
    state: postEditorState,
    dispatcher: postEditorDispatcher,
  } = useContext(PostEditorContext);
  const [charCount, setCharCount] = useState(0);

  const toolbarVisible = postEditorState.toolbarVisible;
  const value = postEditorState.postInProgress.slateValue;

  useEffect(() => {
    const reset = () => {
      setTimeout(() => {
        ReactEditor.focus(editor, editor);
        Transforms.select(editor, {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        });
        Transforms.delete(editor);
      }, 0);
    };
    setOnResetEditor(() => reset);
  }, [editor, setOnResetEditor]);

  useEffect(() => {
    setCharCount(getCharCount(editor));
  }, [editor]);

  const onKeyDown = (event) => {
    if (isBoldHotkey(event)) {
      event.preventDefault();
      toggleBold(editor);
      return true;
    } else if (isItalicHotkey(event)) {
      event.preventDefault();
      toggleItalic(editor);
      return true;
    } else if (isLinkHotkey(event)) {
      event.preventDefault();
      toggleLink(editor);
      return true;
    } else if (isSoftBreakHotkey(event)) {
      event.preventDefault();
      insertSoftBreak(editor);
      return true;
    } else {
      // Propagate to Slate's default onKeyDown-handler
      return false;
    }
  };

  const onClickBold = (event) => {
    event.preventDefault();
    toggleBold(editor);
  };

  const onClickItalic = (event) => {
    event.preventDefault();
    toggleItalic(editor);
  };

  const onClickLink = (event) => {
    event.preventDefault();
    toggleLink(editor);
  };

  // We update selection here because Slate fires an onChange even on pure selection change.
  const handleOnChange = useCallback(
    (doc) => {
      setCharCount(getCharCount(editor));

      // TODO: Can we save editor state to local storage here?
      // https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
      postEditorDispatcher({
        type: postEditorActions.SET_SLATE_VALUE,
        payload: doc,
      });

      setSelection(editor.selection);
    },
    [editor, postEditorDispatcher, setSelection]
  );

  const modifierKey = isOSX ? '⌘' : 'Ctrl';

  const toolbarItems = [
    {
      label: `Fet`,
      shortCutLabel: `${modifierKey}+B`,
      onClick: onClickBold,
      icon: BoldIcon,
      active: isBoldActive(editor),
      testId: 'Bold',
    },
    {
      label: `Kursiv`,
      shortCutLabel: `${modifierKey}+I`,
      onClick: onClickItalic,
      icon: ItalicIcon,
      active: isItalicActive(editor),
      testId: 'Italic',
    },
    {
      label: 'Länk',
      shortCutLabel: `${modifierKey}+K`,
      onClick: onClickLink,
      icon: isLinkSelected(editor) ? UnlinkIcon : LinkIcon,
      active: isLinkSelected(editor),
      testId: 'Link',
    },
  ];

  return (
    <Slate editor={editor} value={value} onChange={handleOnChange}>
      <div className={s.root}>
        {toolbarVisible && (
          <div className={s.editorToolbar}>
            <Toolbar items={toolbarItems} selection={selection} />
          </div>
        )}
        <div className={s.editorWrapper}>
          <Editable
            className={cn(s.editor, { [s.toolbarHidden]: !toolbarVisible })}
            onKeyDown={onKeyDown}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            data-testid="postEditorTextArea"
            title="Inläggets text"
            decorate={decorate}
            /* autoFocus <- breaks cypress type */
          />
        </div>
        {Boolean(charCount) && (
          <div
            className={cn(s.charCounter, {
              [s[`charCounter${textLengthIndicator(charCount)}`]]: true,
            })}
          >
            {charCount}{' '}
            <Tooltip className={s.tooltip}>{`${charCount} tecken${
              textLengthIndicator(charCount) === 'Small'
                ? ''
                : `. Rekommenderad gräns är ${SMALL_TEXT_LENGTH_LIMIT}.`
            }`}</Tooltip>
          </div>
        )}
      </div>
    </Slate>
  );
};

export default TextEditor;
